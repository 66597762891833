.navbar {
    float: none;
    font-family: 'Abril Fatface', cursive;
}

.nav-item {
    border-right: 1px solid silver;
    border-left: 1px solid silver;
    height: 4rem;
    // padding-right: 2rem; 
    // padding-left: 2rem;
}


a.nav-item:hover {
    background: #146760;
    color: #FFFFFF;
}

.nav-link {
    color: #146760;
}
