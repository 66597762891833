.container-fluid {
    color: #146760;
}

.wedding-content {
    margin-bottom: 3vh;
}

.card-img-top {
    padding-top: 1rem;
    height: 25vh;
}

h5 {
    font-family: 'Pacifico', cursive;
}

.card-text {
    font-family: 'Barlow', sans-serif;
}

.list-group-item {
    font-family: 'Barlow', sans-serif;
}