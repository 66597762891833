#rsvp_form {
    font-family: 'Barlow', sans-serif;
    color: #146760;
    background-color: #FFF;
    padding: 1rem;
    border: 2px double darkgray;
    border-radius: 10px;
}

.select-dropdown {
    padding: 0px
}

.submit-button {
    background: #146760 !important;
    border-color: #146760 !important;
}
