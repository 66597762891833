#header {
    font-family: 'Pacifico', cursive;
    color: #FAFAFA;
    padding-top: 2.5rem;
    padding-bottom: 0.5rem;
}

a:hover {
    text-decoration: none;
}

#header-wrapper {
    color: #FAFAFA;
}

#main_container {
    width: auto;
}

#background {
    width: 100vw;
    height: 100vh;
    background-image: url('texture.png');
    position: fixed;
    z-index: -1;
    opacity: 0.4;
}

#info {
    padding-bottom: 1rem;
    font-family: 'Barlow', sans-serif;
}

.white-background {
    background-color: #fafafa;
    /* margin-left: -17vw;
    margin-right: -17vw; */

    /* padding-top: 0.5rem;
    padding-bottom: 1.5rem; */
}

#nav-wrapper {
    padding-top: 0.5rem;
}

#switch_container {
    padding-bottom: 1rem;
}

.jumbotron {
    margin: auto;
    width: 100vw;
    /* padding-bottom: 20rem; */
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
}

* {
    padding: 0px
}
