body {
  margin: 0;
  padding: 0;
  background-color: #146760;
  color: #fafafa;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.success-toastify {
  background: #146760 !important;
}

.fail-toastify {
  background: #800000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar {
  float: none;
  font-family: 'Abril Fatface', cursive; }

.nav-item {
  border-right: 1px solid silver;
  border-left: 1px solid silver;
  height: 4rem; }

a.nav-item:hover {
  background: #146760;
  color: #FFFFFF; }

.nav-link {
  color: #146760; }

#carousel {
    width:100%;
    height:50vh;
    
    border-radius: 2%;
    /* border: 4px double #146760; */
    padding-top: 3rem;
    margin-bottom: 3rem;
}

.subheader {
    /* font-family: 'Pacifico', cursive; */
    color: #146760;
}

.carousel-inner {
    border-radius: 2%;
    overflow: hidden; 
}

.carousel-item img {
    width:100%;
    height:45vh;
}






#rsvp_form {
    font-family: 'Barlow', sans-serif;
    color: #146760;
    background-color: #FFF;
    padding: 1rem;
    border: 2px double darkgray;
    border-radius: 10px;
}

.select-dropdown {
    padding: 0px
}

.submit-button {
    background: #146760 !important;
    border-color: #146760 !important;
}

.container-fluid {
    color: #146760;
}

.wedding-content {
    margin-bottom: 3vh;
}

.card-img-top {
    padding-top: 1rem;
    height: 25vh;
}

h5 {
    font-family: 'Pacifico', cursive;
}

.card-text {
    font-family: 'Barlow', sans-serif;
}

.list-group-item {
    font-family: 'Barlow', sans-serif;
}
.container {
    color: #146760;
}

img.castle {
    height:45vh;
    border-radius: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.info {
    padding-bottom: 35vh;
    padding-top:10vh;
    color: #146760;
    font-family: 'Pacifico', cursive;
}
#header {
    font-family: 'Pacifico', cursive;
    color: #FAFAFA;
    padding-top: 2.5rem;
    padding-bottom: 0.5rem;
}

a:hover {
    text-decoration: none;
}

#header-wrapper {
    color: #FAFAFA;
}

#main_container {
    width: auto;
}

#background {
    width: 100vw;
    height: 100vh;
    background-image: url(/static/media/texture.17aac516.png);
    position: fixed;
    z-index: -1;
    opacity: 0.4;
}

#info {
    padding-bottom: 1rem;
    font-family: 'Barlow', sans-serif;
}

.white-background {
    background-color: #fafafa;
    /* margin-left: -17vw;
    margin-right: -17vw; */

    /* padding-top: 0.5rem;
    padding-bottom: 1.5rem; */
}

#nav-wrapper {
    padding-top: 0.5rem;
}

#switch_container {
    padding-bottom: 1rem;
}

.jumbotron {
    margin: auto;
    width: 100vw;
    /* padding-bottom: 20rem; */
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
}

* {
    padding: 0px
}

