#carousel {
    width:100%;
    height:50vh;
    
    border-radius: 2%;
    /* border: 4px double #146760; */
    padding-top: 3rem;
    margin-bottom: 3rem;
}

.subheader {
    /* font-family: 'Pacifico', cursive; */
    color: #146760;
}

.carousel-inner {
    border-radius: 2%;
    overflow: hidden; 
}

.carousel-item img {
    width:100%;
    height:45vh;
}





